// This is an example of a utility function that we want to use elsewhere in the app.  
// Note the use of typescript here.  

export function ReplaceAll(origStr: string, whatToFind: string, replaceItWith: string): string {
    if (origStr.includes(whatToFind)) {
        let newStr = origStr.replace(whatToFind, replaceItWith);
        return ReplaceAll(newStr, whatToFind, replaceItWith);
    }
    return origStr;
}


export function GetQueryStrParamByName(qsParam: string,
                                       addlQSParam1?: string,
                                       addlQSParam2?: string,
                                       addlQSParam3?: string,
                                       addlQSParam4?: string,
                                       addlQSParam5?: string,
                                       addlQSParam6?: string,
                                       addlQSParam7?: string) {

    qsParam = qsParam.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");

    var regex = new RegExp("[\\?&]" + qsParam + "=([^&#]*)"),
        results = regex.exec(location.search);

    if (results === null) {
        // If there is an alternate variation to qsParam, then try that.
        // Example: qsParam is "quoteNumber" and addlQSParam is "quotenumber" or "QuoteNumber"
        if (addlQSParam1 != null && addlQSParam1 != '') { return GetQueryStrParamByName(addlQSParam1, addlQSParam2, addlQSParam3, addlQSParam4, addlQSParam5, addlQSParam6, addlQSParam7); }
        return "";
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}

export function MassageStringForCommonTokens(str: string): string {
    if (str == undefined || str == null) { return str; }
    // Here is where we are going to make a quick check for our "tokens", like [INSERT YEAR HERE], etc, and replace them with a value that is
    // calculated or fetched.

    let newStr = ReplaceAll(str, "[INSERT YEAR HERE]", (new Date().getFullYear()).toString());

    // insert more replaces here, as needed

    return newStr;
}



export function VerboseConsoleLog(str: string) {
    // If the query string param says to hide all logging, then do so.
    var qsParam = GetQueryStrParamByName('hidealllogging', 'HideAllLogging', 'HIDEALLLOGGING');
    if (qsParam === 'true' || qsParam === 'yes' || qsParam === '1') {
        return;
    }

    // If the query string param says to hide it, then do so.
    qsParam = GetQueryStrParamByName('hideverboseconsolelogging', 'HideVerboseConsoleLogging', 'HIDEVERBOSECONSOLELOGGING');
    if (qsParam === 'true' || qsParam === 'yes' || qsParam === '1') {
        return;
    }

    // Turn on via a query string parameter, which would allow us to show logging in any environment
    qsParam = GetQueryStrParamByName('enableverboseconsolelogging', 'EnableVerboseConsoleLogging', 'ENABLEVERBOSECONSOLELOGGING');
    if (qsParam === 'true' || qsParam === 'yes' || qsParam === '1') {
        // This is a way for us to do all sorts of logging when running only locally, on qual, or on prod, otherwise don't.
        // You must use a query string param to turn this one ON!
        console.log(str);
    }
}

export function ConsoleLog(str: string) {
    // If the query string param says to hide all logging, then do so.
    var qsParam = GetQueryStrParamByName('hidealllogging', 'HideAllLogging', 'HIDEALLLOGGING');
    if (qsParam === 'true' || qsParam === 'yes' || qsParam === '1') {
        return;
    }

    // If the query string param says to hide it, then do so.
    qsParam = GetQueryStrParamByName('hideconsolelogging', 'HideConsoleLogging', 'HIDECONSOLELOGGING');
    if (qsParam === 'true' || qsParam === 'yes' || qsParam === '1') {
        return;
    }

    // Turn on via a query string parameter, which would allow us to show logging in Prod for example
    qsParam = GetQueryStrParamByName('enableconsolelogging', 'EnableConsoleLogging', 'ENABLECONSOLELOGGING');
    if (qsParam === 'true' || qsParam === 'yes' || qsParam === '1') {
        if (IsAProdSiteURL()) {
            console.log(str);
            return;
        }
    }

    // This is a way for us to do all sorts of logging when running in non-prod, otherwise don't.
    if (IsANonProdSiteURL() || IsALocalSiteURL()) {
        console.log(str);
    }
};


export function IsAProdSiteURL() {
    var hostName = window.location.hostname.toLowerCase();
    return hostName == "ultragard.getgard.com";
};

export function IsANonProdSiteURL() {
    var hostName = window.location.hostname.toLowerCase();
    return hostName == "ultragardqual.getgard.com" ||
           hostName == "ultragardtest.getgard.com" ||
           hostName == "ultragardnonprod.getgard.com" ||
           hostName == "localhost.sentry.com";
};

export function IsALocalSiteURL() {
    var hostName = window.location.hostname.toLowerCase();
    return hostName == "ultragarddev.sentry.com" ||
           hostName.indexOf("localhost") >= 0;
};

export function AddDeviceClassToBody(action: string) {
    //VerboseConsoleLog('AddDeviceClassToBody: ' + action);

    // First remove all classes
    document.body.classList.remove('DeviceSmallPhone');
    document.body.classList.remove('DevicePhone');
    document.body.classList.remove('DeviceTablet');
    document.body.classList.remove('DeviceDesktop');
    document.body.classList.remove('DeviceLargeDesktop');
    document.body.classList.remove('DeviceXLargeDesktop');

    // Next, add the correct one
    if (IsOnSmallPhoneSizedScreen()) {
        document.body.classList.add('DeviceSmallPhone');
    } else if (IsOnPhoneSizedScreen()) {
        document.body.classList.add('DevicePhone');
    } else if (IsOnTabletSizedScreen()) {
        document.body.classList.add('DeviceTablet');
    } else if (IsOnDesktopSizedScreen()) {
        document.body.classList.add('DeviceDesktop');
    } else if (IsOnLargeDesktopSizedScreen()) {
        document.body.classList.add('DeviceLargeDesktop');
    } else if (IsOnXLargeDesktopSizedScreen()) {
        document.body.classList.add('DeviceXLargeDesktop');
    }

    //VerboseConsoleLog('AddDeviceClassToBody: ' + action + '  DONE');
};

// https://stackoverflow.com/questions/18575582/how-to-detect-responsive-breakpoints-of-twitter-bootstrap-3-using-javascript
export function IsOnSmallPhoneSizedScreen(): boolean {
    return document.getElementById('device-xs').checkVisibility();
};
export function IsOnPhoneSizedScreen(): boolean {
    return document.getElementById('device-sm').checkVisibility();
};
export function IsOnTabletSizedScreen(): boolean {
    return document.getElementById('device-md').checkVisibility();
};
export function IsOnDesktopSizedScreen(): boolean {
    return document.getElementById('device-lg').checkVisibility();
};
export function IsOnLargeDesktopSizedScreen(): boolean {
    return document.getElementById('device-xl').checkVisibility();
};
export function IsOnXLargeDesktopSizedScreen(): boolean {
    return document.getElementById('device-xxl').checkVisibility();
};

export function WhatDeviceAmI(): string {
    if (IsOnSmallPhoneSizedScreen()) {
        return 'SMALL PHONE';
    } else if (IsOnPhoneSizedScreen()) {
        return 'PHONE';
    } else if (IsOnTabletSizedScreen()) {
        return 'TABLET';
    } else if (IsOnDesktopSizedScreen()) {
        return 'DESKTOP';
    } else if (IsOnLargeDesktopSizedScreen()) {
        return 'LARGE DESKTOP';
    } else if (IsOnXLargeDesktopSizedScreen()) {
        return 'XL DESKTOP';
    } else {
        return 'UNKNOWN VIEWPORT';
    }
};




export function CommafyValue(nStr: string) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
};

export function RemoveCommas(nStr: string) {
    var ReplacedNumber = nStr.replace(/\,/g, '');
    return ReplacedNumber;
};
export function RemoveDollarSign(nStr: string) {
    var ReplacedNumber = nStr.replace(/\$/g, '');
    return ReplacedNumber;
};

export function RemoveLeadingZeros(s: string) {
    while (s.charAt(0) == '0') {
        if (s.length == 1) { break };
        s = s.substr(1, s.length - 1);
    }
    if (s.length == 0) s = '0';
    return s;
};

export function RemoveNoneAlphaNumChars(s: string) {
    return s.replace(/[^a-zA-Z 0-9]+/g, '');
};

export function IsNumberKey(evt: JQuery.KeyDownEvent, allowDecimal: boolean, allowNegative: boolean) {
    // http://stackoverflow.com/questions/2808184/restricting-input-to-textbox-allowing-only-numbers-and-decimal-point
    // http://stackoverflow.com/questions/995183/how-to-allow-only-numeric-0-9-in-html-inputbox-using-jquery
    var charCode = (evt.which) ? evt.which : evt.keyCode;

    //ConsoleLog('charCode = ' + charCode);

    if (allowDecimal == undefined) { allowDecimal = true; }
    if (allowNegative == undefined) { allowNegative = true; }

    // http://www.asciitable.com/
    if (charCode == 13) { return true; } // always allow the enter button (regular key and num pad key both use 13)
    if (charCode == 8) { return true; } // always allow the backspace button
    if (charCode == 46) { return true; } // always allow the delete button
    if (charCode == 9) { return true; } // always allow the tab button
    if (charCode == 27) { return true; } // always allow the escape button
    if (charCode == 37 || charCode == 39) { return true; } // always allow the left and right keys
    if ((charCode == 110 || charCode == 190) && allowDecimal === true) { return true; } // possibly allow the decimal point (regular key and num pad key)
    if ((charCode == 45 || charCode == 109) && allowNegative === true) { return true; } // possibly allow the minus sign (regular key and num pad key)
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) { return true; } // 0-9 (regular keys and num pad keys)
    return false;
};
import * as $ from 'jquery';
import { ShowModalCustom, HideAllModals } from '@sentry-insurance/InternalFrontendTemplate';
import SentryModal from '@sentry-insurance/InternalFrontendTemplate/dist/Interfaces/SentryModal';

// import jquery plugins for the whole application here.
import 'bootstrap';
import 'jquery-validation-unobtrusive';
import '@sentry-insurance/InternalFrontendTemplate/dist/jquery.validate.unobtrusive.sentry.js';

// Using 'imports-loader' because jquery.unobtrusive-ajax.js doesn't know how to import jQuery on its own. - https://webpack.js.org/loaders/imports-loader/
import 'imports-loader?imports=default|jquery|jQuery!jquery-ajax-unobtrusive/dist/jquery.unobtrusive-ajax.js';
import './mdb';

// import SCSS / CSS for the whole application here.
import '../css/app.scss';
import '../css/vendor.scss';

// import other utilities and helpers for use later on this page.
import { InitializeHeader } from './header';
import { AddDeviceClassToBody, IsANonProdSiteURL, VerboseConsoleLog, WhatDeviceAmI } from './shared';

/// <summary>
/// Page Initialization for the _Layout.cshtml view
/// </summary>
export function Init() {
    $(function () {
        // Init our dataLayer
        window['dataLayer'] = window['dataLayer'] || [];

        MassageHtmlForSpecialChars();

        // Header component
        // --------------------------------------------------------
        InitializeHeader();
        // --------------------------------------------------------

        if (IsANonProdSiteURL()) {
            document.getElementById('DeviceType').classList.remove('Hidden');
        }

        let deviceType = document.getElementById('DeviceType') as HTMLElement
        deviceType.innerHTML = WhatDeviceAmI();
        AddDeviceClassToBody('INIT');

        addEventListener("resize", (event) => {
            let deviceType = document.getElementById('DeviceType') as HTMLElement
            deviceType.innerHTML = WhatDeviceAmI();
            AddDeviceClassToBody('RESIZE');
        });
    });
}

function MassageHtmlForSpecialChars() {
    VerboseConsoleLog('ReplaceSpecChars: Layout Init');

    // This method is designed to find certain divs in the page and look for the copyright, registered
    // and trademark symbols and put a <sup> tag around them.  It will also look for the encoded versions
    // of those (ie &reg;, &copy;, &trade;)
    // http://stackoverflow.com/questions/19364581/adding-superscript-sup-tags-around-all-trademark-and-registered-trademark-symb

    try {
        var selector = 'body .ReplaceSpecialChars';

        $(selector).each(function () {
            var html = $(this).html();

            // First, replace all of the combinations of the reg into &reg;
            html = html.replace(/®/gi, '&reg;').replace(/&#174;/gi, '&reg;').replace(/&amp;reg;/gi, '&reg;');
            // Next, replace all places where there is a <sup> tag around the reg and change to just &reg;
            html = html.replace(/<sup>&reg;<\/sup>/gi, '&reg;');
            // Now look for &reg; and put the sup tag around it
            html = html.replace(/&reg;/gi, '<sup>&reg;</sup>');

            // First, replace all of the combinations of the trade into &trade;
            html = html.replace(/™/gi, '&trade;').replace(/&#8482;/gi, '&trade;').replace(/&amp;trade;/gi, '&trade;');
            // Next, replace all places where there is a <sup> tag around the trade and change to just &trade;
            html = html.replace(/<sup>&trade;<\/sup>/gi, '&trade;');
            // Now look for &trade; and put the sup tag around it
            html = html.replace(/&trade;/gi, '<sup>&trade;</sup>');

            // First, replace all of the combinations of the copy into &copy;
            html = html.replace(/©/gi, '&copy;').replace(/&#169;/gi, '&copy;').replace(/&amp;copy;/gi, '&copy;');
            // Next, replace all places where there is a <sup> tag around the copy and change to just &copy;
            html = html.replace(/<sup>&copy;<\/sup>/gi, '&copy;');
            // Now look for &copy; and put the sup tag around it
            html = html.replace(/&copy;/gi, '<sup>&copy;</sup>');

            // - fin -
            $(this).html(html);
        });
    }
    catch (err) {
        console.error(err);
    }
}

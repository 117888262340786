export function InitializeHeader() {
    setActiveNavLineCorrectly();
    handleScroll();
    backgroundBlur();
}

export function setActiveNavLineCorrectly(){
    //     // ---------------------------------------------
    //     //replacement of navActiveLine
    let originalWidth = 0;
    let originalPosition = 0;
    const activeLineModules = document.querySelectorAll('[data-module="navActiveLine"]');

    activeLineModules.forEach((element) => {
        let $navLinks = element.querySelectorAll('.mainNavLinks a.nav-link');
        let $activeLine = element.querySelector('.active-nav-line') as HTMLElement;
        let $activeLink = element.querySelector('.mainNavLinks a.active') as HTMLElement;
        if ($navLinks.length > 0 && $activeLine != null) {
            try {
                // Turn this to false if you want to ONLY show the hover when there is an active link.
                let showHoverUnderlineAllTheTime = false;
                let originalActiveLink = element.querySelector('.mainNavLinks a.active') as HTMLElement;

                if (!showHoverUnderlineAllTheTime) {
                    showHoverUnderlineAllTheTime = (originalActiveLink != null);
                }

                if (showHoverUnderlineAllTheTime) {
                    if (originalActiveLink != null) {
                        originalWidth = originalActiveLink.offsetWidth;
                        originalPosition = originalActiveLink.offsetLeft;
                    } 

                    $activeLine.style.width = originalWidth + "px";
                    $activeLine.style.left = originalPosition + "px";

                    $navLinks.forEach(($navLink) => {

                        $navLink.addEventListener('mouseenter', function (e) {
                            const target = e.target as HTMLElement;
                            if (target != null && target.classList.contains('active')) {

                            } else if (target != null) {
                                var newWidth = target?.offsetWidth;
                                var newLeftPosition = target?.offsetLeft;
                                $activeLine.style.width = newWidth + 'px' || '';
                                $activeLine.style.left = newLeftPosition + 'px' || '';
                            }
                        });
                        $navLink.addEventListener('mouseleave', function (e) {
                            $activeLine.style.width = originalWidth + "px";
                            $activeLine.style.left = originalPosition + "px";
                        });
                    })
                }

                if ($activeLink != null) {
                    // Setting the active line underneath the active link as the new starting position
                    $activeLine.style.width = $activeLink?.offsetWidth + "px";
                    $activeLine.style.left = $activeLink?.offsetLeft + "px";

                    // Setting the new original position of the active link so
                    // that mouseover/mouseleave starts and ends up in the new position
                    originalWidth = $activeLink?.offsetWidth;
                    originalPosition = $activeLink?.offsetLeft;
                } else if ($activeLine != null) {
                    // If we aren't on any of the main pages where the active line is being used 
                    // then just set the active line and positioning back to default;
                    $activeLine.style.width = "0px";
                    $activeLine.style.left = "0px";
                    originalWidth = 0;
                    originalPosition = 0;
                }
            }
            catch (exc) {
                console.error('Unhandled exception hit in navActiveLine inside Header.js!', exc);
            }
        }
    });
}

window.addEventListener("resize", function () {
    setActiveNavLineCorrectly();
})

window.addEventListener( "scroll", function () {
    
    handleScroll();
})

window.addEventListener("click", function () {
    backgroundBlur();
})

export function backgroundBlur() {

    setTimeout(function waitForMenuLoad() {
        let navbarCollapse = document.getElementById("navbarSupportedContent");

        if (navbarCollapse != null) {
            if (navbarCollapse.classList.contains("show")) {
                document.body.classList.add('background-blur');
            } else {
                document.body.classList.remove('background-blur');
            }
        } 
    }, 500);
}

export function handleScroll(){
    let prevScrollpos = 0;
    let currentScrollPos = window.pageYOffset;
    const topBarHeight = 45.281;
    const upperMenu = document.getElementById("header-top");
    const lowerMenu = document.getElementById("header-bottom");

    /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
    if (upperMenu != null && lowerMenu != null) {
        
        if (currentScrollPos == prevScrollpos) {
            upperMenu.style.top = "0";
            lowerMenu.style.top = topBarHeight + "px";
                
        } else if (currentScrollPos > prevScrollpos) {
            upperMenu.style.top = -topBarHeight + "px";
            lowerMenu.style.top = "0";
                
        } 
    }
}